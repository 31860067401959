import Img, { ImageProps } from 'next/image'
import React from 'react'

const imageKitLoader =
  (watermark?: boolean) =>
  ({ src, width, quality }) => {
    if (src[0] === '/') src = src.slice(1)
    const params = [`w-${width}`]
    if (quality) {
      params.push(`q-${quality}`)
    }
    if (watermark) {
      params.push(
        `l-text,lfo-top_right,fs-18,pa-10,i-Imagem%20e%20cores%20meramente%20ilustrativas%20e%20sujeitas%20a%20disponibilidade.,bg-white,fs-45,l-end`
      )
    }
    const paramsString = params.join(',')

    return `${src}?tr=${paramsString},bg-fff,f-auto`
  }

const Image: React.FC<Omit<ImageProps, 'loader' | 'blurDataURL' | 'placeholder'> & { watermark?: boolean }> = ({
  watermark,
  ...props
}) => {
  const shimmer = (w: number, h: number) => `
      <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient id="g">
            <stop stop-color="#fff" offset="20%" />
            <stop stop-color="#ccc" offset="50%" />
            <stop stop-color="#fff" offset="70%" />
          </linearGradient>
        </defs>
        <rect width="${w}" height="${h}" fill="#fff" />
        <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
        <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
      </svg>
    `

  const toBase64 = (str: string) =>
    typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str)

  return (
    <Img
      {...props}
      loader={imageKitLoader(watermark)}
      placeholder="blur"
      blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(props.width as number, props.height as number))}`}
    />
  )
}

export default Image
